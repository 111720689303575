export const message = {
    'FORM_SUBMISSION_SUCCESS': 'Forms have been sent successfully.',
    'EMPTY_PASSWORD': 'Please provide password',
    'PASSWORD_LENGTH': 'Password must contain atleast 8 characters',
    'PASSWORD_NOT_MATCH': 'The password and repeated password do not match',    
    'EMPTY_CONFIRM_PASSWORD': 'Please provide confirm password',
    'PASSWORD_CHANGE_SUCCESS': 'Password has been changed successfully',
    'EMPTY_EMAIL': 'Please provide email',
    'INVALID_EMAIL': 'Please provide valid email',
    'USER_UPDATE_SUCCESS': 'User has been changed successfully',
    'REQUEST_SUBMISSION_SUCCESS': 'Request has been submitted.',
    'REQUEST_SUCCESS': 'Request has been submitted successfully.',
    'PROVIDE_NAME': 'Please provide customer name.',
    'DISPOSITION_SUCCESS': 'Disposition has been logged.',
    'OFFLINE_MESSAGE': 'INTERNET CONNECTIVITY ISSUE',
    'DELETE_SUCCESSFULLY': 'Deleted successfully.',
    'NO_CREDIT_CHECK_TEXT': 'no credit application required',
    'WITH_CREDIT_CHECK_TEXT': "credit application req'd for appliance",
    'INVITE_SUBMISSION_SUCCESS': 'Invitation has been sent successfully.',
    'EMPTY_FIRST_NAME': 'Please provide first name',
    'EMPTY_LAST_NAME': 'Please provide last name',
    'RESEND_EMAIL_SUCCESS': 'Resend successfully.',
};


import firebase from 'firebase';

const firebaseConfig = {
    apiKey: "AIzaSyCb0Dv-wseaUUbg0-F5a2Bu6mLh5OZzAi8",
    authDomain: "tcp-293717.firebaseapp.com",
    projectId: "tcp-293717",
    storageBucket: "tcp-293717.appspot.com",
    messagingSenderId: "733665276412",
    appId: "1:733665276412:web:acf213ff9ea01e07fa8279",
    measurementId: "G-D1V627LGQ6"
  };

// Initialize Firebase
if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
}
const analytics = firebase.analytics()

const addEvent = (event, action, obj) => {
    analytics.logEvent(event, {
        'timestamp': Date.now(),
        'action': action,
        'ip': localStorage.getItem('ip_address'),
        'action_by_role': localStorage.getItem('role'),
        'action_by_name': localStorage.getItem('dealer_name'),
        'action_by_email': localStorage.getItem('email'),
        'action_by_id': localStorage.getItem('email'),
        'app_id': "web:acf213ff9ea01e07fa8279",
        'version': "1.0",
        "app_info": {
            id: "web:acf213ff9ea01e07fa8279",
            version: '1.0'
        },  
        ...obj
    });
}

export {
    firebase,
    addEvent
}